import React from 'react'
import { SelectOptionItemType } from '@revolut/ui-kit'
import { FinalGrade } from '@src/interfaces/performance'
import { selectorKeys } from '@src/constants/api'
import RadioSelectInput, {
  RadioSelectInputProps,
} from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { PerformanceGradeRevamped } from '@components/PerformanceGrade/PerformanceGradeRevamped'

export type SelectGradeHandler = RadioSelectInputProps<{ id: FinalGrade }>['onChange']
interface GradeSelectorProps extends RadioSelectInputProps<{ id: FinalGrade }> {
  emptyOptionLabel?: string
}

export const GradeSelector = ({
  emptyOptionLabel = 'System recommended grade',
  children,
  selector,
  ...props
}: GradeSelectorProps) => {
  const optionRenderer = (item: SelectOptionItemType<{ id: FinalGrade }>) => {
    if (item.value.id === FinalGrade.Empty) {
      return emptyOptionLabel
    }
    return (
      <PerformanceGradeRevamped
        grade={{ id: item.value.id, label: item.label ? String(item.label) : '' }}
        inverse
      />
    )
  }
  return (
    <RadioSelectInput<{ id: FinalGrade }>
      selector={selector || selectorKeys.calibration_grades}
      searchable={false}
      fitInAnchor={false}
      {...props}
    >
      {optionRenderer}
    </RadioSelectInput>
  )
}
