import {
  FinalGrade,
  FinalGradeInterface,
  PerformanceCycleInterface,
  PerformanceRating,
} from '@src/interfaces/performance'
import {
  EmployeeOptionInterface,
  IdStatuses,
  NameIdInterface,
  AvatarType,
} from '@src/interfaces/employees'
import { PerformanceScores } from '@src/interfaces/scorecard'
import { FieldOptions } from '@src/interfaces'
import { SeniorityInterface, SenioritySublevelInterface } from '@src/interfaces/seniority'

export enum ContributorType {
  IC = 'individual_contributor',
  Mgr = 'high_impact_individual',
  PersonalKPI = 'personal_kpi_individual',
}

export interface TalentPerformanceEmployee {
  id: number
  full_name: string
  display_name: string
  status: IdStatuses
  name: string
  avatar?: AvatarType
}

export interface TalentPerformanceInterface {
  id: number
  employee: TalentPerformanceEmployee
  cycle: PerformanceCycleInterface
  line_manager: EmployeeOptionInterface
  functional_manager: EmployeeOptionInterface
  line_manager_grade: PerformanceScores
  functional_manager_grade: PerformanceScores
  calibrated_grade: PerformanceScores
  self_employee_locked: boolean
  grade_calibration_needed: boolean
  department_owner_grade_suggestion: FinalGradeInterface | null
  department_owner_grade_suggestion_comment?: string | null
  function_owner_grade_suggestion: FinalGradeInterface | null
  function_owner_grade_suggestion_comment?: string | null
  performance_extra_grade_suggestion: FinalGradeInterface | null
  absolute_rating_label: PerformanceRating
  calculated_rating_label: PerformanceRating
  absolute_rating_score: number | null
  deliverables_rating_label: PerformanceRating
  deliverables_rating_score: number
  reviewed_employee_type: ContributorType
  functional_skills_label: PerformanceRating
  display_grade: FinalGradeInterface
  calibration_flags?: string[]
  seniority?: SeniorityInterface
  specialisation?: NameIdInterface
  ranking_score?: number | null
  calculated_grade?: FinalGrade | null
  functional_manager_grade_suggestion?: FinalGradeInterface | null
  line_manager_grade_suggestion?: FinalGradeInterface | null
  function_owner_rating_label?: PerformanceRating | null
  department_owner_rating_label?: PerformanceRating | null
  culture_rating_label?: PerformanceRating | null
  field_options: FieldOptions
  specialisation_seniority_sublevel?: SenioritySublevelInterface
  team_name: string
  department_grade_calibrator?: EmployeeOptionInterface
  function_grade_calibrator?: EmployeeOptionInterface
}

export interface TalentPerformanceStatsInterface {
  functional_manager_completed_review_ratio: number | null
  line_manager_completed_review_ratio: number | null
  calibration_needed_employees_count: number | null
  ongoing_pip_employees_count: number | null
  above_expectations_and_exceptional_percentage: number | null
  unsatisfactory_and_below_expectations_percentage: number | null
  lm_fm_mismatch_count: number | null
  underperformers_count: number | null
  function_owner_calibrated_count: number | null
  department_owner_calibrated_count: number | null
  nips: number | null
  headcount: number | null
  nominated_for_promotion_count: number | null
  hod_hof_mismatch_count: number | null
  department_owner_non_calibrated_count: number | null
  function_owner_non_calibrated_count: number | null
  consistent_average_plus: number | null
  historical_underperformance: number | null
  hidden_historical_underperformance: number | null
  potential_inflation: number | null
}

export interface TalentPerformanceCommentInterface {
  object_id: number
  comments_count: number
}

export type TalentBulkGradeUpdateInterface =
  | {
      id: number
      function_owner_grade_suggestion: FinalGrade
    }
  | {
      id: number
      department_owner_grade_suggestion: FinalGrade
    }

export interface TalentPerformanceBulkGradeUpdateRequest {
  id: number
  function_owner_grade_suggestion: FinalGrade
  department_owner_grade_suggestion: FinalGrade
  display_grade: FinalGrade
}

export interface TalentPerformanceBulkGradeUpdateResponse {
  id: number
  function_owner_grade_suggestion: FinalGradeInterface
  department_owner_grade_suggestion: FinalGradeInterface
  display_grade: FinalGradeInterface
}

export type TalentPerformanceDelegateInterface = {
  grades: number[] | null
  include_all: boolean
  exclude_ids?: number[] | null
  department_grade_calibrator?: { id: number } | null
  function_grade_calibrator?: { id: number } | null
}

export type TalentPerformanceDelegateResponse = Pick<
  TalentPerformanceInterface,
  'id' | 'function_grade_calibrator' | 'department_grade_calibrator'
>

export interface TalentPerformanceRelationsInterface {
  id: number
  cycle: PerformanceCycleInterface
  employee: EmployeeOptionInterface
  function_owner: EmployeeOptionInterface
  department_owner: EmployeeOptionInterface
  specialisation_owner: EmployeeOptionInterface
  line_manager: EmployeeOptionInterface
  functional_manager: EmployeeOptionInterface
  team_owner: EmployeeOptionInterface
}
